@import "https://cdn.jsdelivr.net/npm/sequential-workflow-designer@0.13.2/css/designer-light.css";
@import "https://cdn.jsdelivr.net/npm/sequential-workflow-designer@0.13.2/css/designer.css";
@font-face {
  font-family: Roboto;
  src: url("Roboto-Regular-webfont.55e67705.woff") format("woff");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: Roboto;
  src: url("Roboto-Italic-webfont.2bbc16da.woff") format("woff");
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: Roboto;
  src: url("Roboto-Bold-webfont.699eb688.woff") format("woff");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: Roboto;
  src: url("Roboto-Light-webfont.29fa1411.woff") format("woff");
  font-style: normal;
  font-weight: 300;
}

body {
  color: #444;
  margin: 0;
  font-family: Roboto, sans-serif;
}

button:focus {
  outline: none;
  display: inline-block;
}

input[type="text"] {
  background-color: #fff;
  border: 1px solid #999;
  border-radius: 3px;
  font-weight: normal;
  display: inline-block;
}

input[type="file"]::-webkit-file-upload-button {
  visibility: hidden;
}

input[type="range"] {
  margin: 0;
}

input[type="range"]::-moz-range-track {
  background-color: #ccc;
}

input[type="color"] {
  height: 18px;
  padding: 0;
}

button, input[type="file"] {
  cursor: pointer;
  color: #fff;
  background-color: gray;
  border: 0;
  border-radius: 4px;
  margin: 3px;
  padding: 3px 6px;
  box-shadow: 0 1px 3px #0003, 0 2px 2px #00000024, 0 3px 1px -2px #0000001f;
}

.img-button {
  cursor: pointer;
  padding: 0 5px;
}

a:link, a:visited {
  color: inherit;
}

#usermanual-button {
  z-index: 500;
  color: #fff;
  cursor: pointer;
  background-color: #6495ed;
  border-radius: 6px 6px 0 0;
  padding: 0 5px;
  font-size: 1.2em;
  position: absolute;
  top: 350px;
  transform: translateX(-38%)rotate(90deg);
}

#usermanual-button a {
  text-decoration: none;
}

#feedback-button {
  z-index: 500;
  color: #fff;
  cursor: pointer;
  background-color: #b22222;
  border-radius: 6px 6px 0 0;
  padding: 0 5px;
  font-size: 1.2em;
  position: absolute;
  top: 500px;
  transform: translateX(-38%)rotate(90deg);
}

#feedback-button a {
  text-decoration: none;
}

.ModalPopup {
  z-index: 990;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.ModalPopup__overlay {
  z-index: 995;
  background: #000000d9;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ModalPopup__content {
  z-index: 999;
  box-sizing: border-box;
  background: #fff;
  border-radius: 4px;
  max-width: 60%;
  max-height: 90%;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: auto;
  transform: translate(-50%, -50%);
  box-shadow: 0 1px 5px #000000b3;
}

.ModalPopup__close-button {
  z-index: 999;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  display: block;
  position: absolute;
  top: 2%;
  left: 48%;
}

.UserMsgBox {
  z-index: 999;
  max-width: 40%;
  display: block;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translate(-50%);
}

.alert {
  border-radius: 5px;
  width: auto;
  height: auto;
  margin: 10px;
  padding: 10px;
  font-family: sans-serif;
  font-weight: 400;
  line-height: 1.8;
  position: relative;
  top: 10px;
  left: 0;
}

.alertText {
  text-align: center;
  margin: 0 auto;
  font-size: 16px;
  display: table;
}

.success {
  color: #9a9;
  background-color: #efe;
  border: 1px solid #ded;
}

.notice {
  color: #9aa;
  background-color: #eff;
  border: 1px solid #dee;
}

.warning {
  color: #c9971c;
  background-color: #fdf7df;
  border: 1px solid #feec6f;
}

.error {
  color: #a66;
  background-color: #fee;
  border: 1px solid #edd;
}

.UserMsgBox.error {
  color: #780000;
  background-color: #ffd2d2;
  box-shadow: 0 1px 1px #780000;
}

.DropDownMenu div:hover {
  cursor: default;
  position: relative;
}

.DropDownMenu div:hover ul {
  opacity: 1;
  visibility: visible;
  display: block;
}

.DropDownMenu ul {
  opacity: 0;
  visibility: hidden;
  width: 150px;
  padding: 0;
  display: none;
  position: absolute;
  top: 10px;
  left: 0;
  box-shadow: 0 2px 5px #00000029, 0 2px 10px #0000001f;
}

.DropDownMenu ul li {
  background: #fff;
  padding: 5px;
  display: block;
}

.Switch .label {
  font-size: 14px;
}

.switch {
  box-sizing: content-box;
  text-align: center;
  color: #aaa;
  cursor: pointer;
  border: 2px solid #eceeef;
  border-radius: 9px;
  width: 36px;
  height: 18px;
  margin: 0;
  padding: 0;
  font-size: 11px;
  line-height: 20px;
  transition: all .3s;
  display: inline-block;
}

.switch-on {
  color: gray;
  text-shadow: 0 1px #0003;
  border-color: gray;
  font-weight: bold;
  box-shadow: inset 0 1px 2px #00000026, inset 0 0 3px #0003;
}

.addon-box {
  box-sizing: content-box;
  border: 2px solid #eceeef;
  border-radius: 9px;
  margin-right: 6px;
  padding: 6px 12px;
  transition: all .3s;
  display: inline-block;
}

.addon {
  cursor: pointer;
  padding-bottom: 4px;
  font-size: 16px;
}

.structureSelector {
  color: #444;
  cursor: pointer;
}

.structure-selected {
  color: #000;
  border: 2px solid #000;
}

.Structure-Info {
  padding-top: 18px;
}

.code-selection-box, .gims-selection-box {
  border-right: 1px solid #ccc;
  padding-right: 20px;
  font-size: 14px;
}

.gims-selection-box {
  padding-top: 6px;
}

.code-selection-box img {
  cursor: pointer;
  vertical-align: middle;
}

.code-selected {
  background: #eee;
  border-color: gray;
  border-radius: 5px;
  box-shadow: inset 0 2px 4px #00000026, inset 0 0 3px #0003;
}

.settings-button {
  text-align: center;
  cursor: pointer;
  padding-left: 12px;
  font-size: 9px;
}

.tab {
  margin-top: 20px;
}

.tab-item {
  cursor: pointer;
  border-top: 1px solid #000;
  border-left: 1px solid #000;
  border-right: 1px solid #000;
  padding: 5px;
}

.tab-selected {
  font-weight: bold;
}

.page-section {
  margin: 30px 0;
}

table.set-of-fields {
  border-spacing: 8px;
  padding: 6px;
}

.set-of-fields tr td:first-child {
  font-size: 96%;
  font-weight: bold;
}

.set-of-fields tr td:nth-child(2) {
  text-align: right;
}

.page-section-title {
  border-bottom: 1px solid #ddd;
  padding-left: 10px;
  font-size: 20px;
  font-weight: bold;
}

.section-content {
  justify-content: space-between;
  padding-top: 16px;
  display: flex;
}

.Pagination-selector {
  text-align: center;
}

.Form .user-msg {
  color: red;
  text-align: center;
  padding: 16px 0;
  font-size: smaller;
}

.Form .Form-button-box {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 0;
}

.Form .Form-button-box button {
  padding: 8px 16px;
}

.Tooltip {
  z-index: 100;
  background-color: #ffffc8;
  border-radius: 4px;
  width: 300px;
  padding: 10px 20px;
  font-size: 13px;
  font-weight: normal;
  position: absolute;
  box-shadow: 0 2px 5px #00000029, 0 2px 10px #0000001f;
}

.Tooltip:after {
  content: " ";
  border: 7px solid #0000;
  border-right-color: #aaa;
  margin-top: -7px;
  position: absolute;
  top: 50%;
  right: 100%;
}

#app-header {
  justify-content: space-between;
  max-width: 1200px;
  margin: auto;
  padding-top: 10px;
  padding-bottom: 16px;
  display: flex;
}

#app-title {
  cursor: pointer;
  font-size: 1.15em;
  text-decoration: none;
}

#app-title span {
  font-weight: 300;
}

.desktop-app-download {
  cursor: pointer;
  font-size: .9em;
  font-weight: 300;
}

.settings-form {
  padding: 16px 16px 0;
}

.settings-form > div {
  justify-content: space-evenly;
  margin: 20px 0;
  display: flex;
}

.settings-form div > label {
  width: 52%;
  font-weight: bold;
  display: inline-block;
  position: relative;
}

.settings-form .value-box {
  box-sizing: border-box;
  width: 40%;
}

.settings-form input, .settings-form select {
  box-sizing: border-box;
  border: 1px solid #c2c2c2;
  border-radius: 3px;
  outline: none;
  width: 100%;
  padding: 5px 7px;
  box-shadow: 1px 1px 4px #ebebeb;
}

#app-dashboard {
  justify-content: space-evenly;
  margin-top: 50px;
}

.dashboard-h1 {
  color: #aaa;
  border-bottom: 1px solid #ccc;
  margin: 0 80px;
  font-size: 24px;
  font-weight: lighter;
}

.dashboard-row {
  flex-flow: wrap;
  margin: 0 40px 40px;
  display: flex;
}

.dashboard-button {
  color: #777;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 13px;
  justify-content: space-between;
  align-items: center;
  width: 380px;
  margin: 40px 0 40px 60px;
  font-size: 24px;
  display: flex;
  position: relative;
}

.dashboard-button img.main {
  border-radius: 13px 0 0 13px;
  height: 80px;
}

.dashboard-button img.help-button {
  cursor: help;
}

.dashboard-button img.help-button:hover ~ .dashboard-button-explanation {
  display: block;
}

.surface-input-div img.help-button {
  padding-left: 15px;
}

.surface-input-div img.help-button:hover ~ .button-explanation {
  display: block;
}

.dashboard-button-explanation {
  z-index: -10;
  border: 1px solid #ccc;
  border-radius: 13px;
  padding: 25px 16px 8px;
  font-size: 13px;
  font-weight: lighter;
  display: none;
  position: absolute;
  top: 65px;
  left: 0;
}

.button-explanation {
  color: #777;
  padding-top: 2px;
  padding-left: 6px;
  font-size: 12px;
  font-weight: lighter;
  display: none;
}

.AssistantHeader {
  box-sizing: border-box;
  background-color: #eee;
  border: 1px solid #bbb;
  border-radius: 3px;
  max-width: 1200px;
  margin: 12px auto;
  padding: 12px 20px;
  display: flex;
}

.prev-button-box {
  text-align: left;
  justify-content: flex-start;
  align-items: center;
  width: 27%;
  display: flex;
}

.workflow-centered-box {
  width: 46%;
}

.workflow-title-box {
  text-align: center;
  padding-bottom: 10px;
  font-size: 1.5em;
}

.step-label-box {
  text-align: center;
  vertical-align: -20px;
  font-size: 1.1em;
  font-weight: bold;
}

.next-button-box {
  text-align: right;
  justify-content: flex-end;
  align-items: center;
  width: 27%;
  display: flex;
}

.AssistantHeader button {
  vertical-align: -20px;
  padding-top: 4px;
  font-size: 1.2em;
}

.AssistantHeader img {
  vertical-align: middle;
}

.workflow-designer {
  box-sizing: border-box;
  border: 1px solid #bbb;
  border-radius: 3px;
  max-width: 1200px;
  margin: 12px auto;
  display: flex;
}

.sqd-toolbox {
  width: 150px;
}

.sqd-toolbox-filter, .sqd-toolbox-item {
  width: 130px;
}

.swd-editor {
  margin: 15px;
  padding: 6px;
  line-height: 1.4;
}

.swd-editor button {
  font-size: 1.1em;
}

.swd-editor-title {
  text-align: center;
  padding: 2mm 0;
  font-weight: bold;
}

.swd-editor-div {
  justify-content: center;
  display: flex;
}

.swd-editor-div select {
  width: 80%;
}

.StructureBuilderMod {
  justify-content: center;
  max-width: 1200px;
  margin: auto;
  display: flex;
}

.structure-viewer-info {
  width: 74%;
  position: relative;
}

.structure-info {
  cursor: move;
  width: 100%;
  padding-left: 20px;
  position: relative;
}

.GeometryImporter form {
  text-align: center;
  width: 480px;
}

.StructureViewer, .BZViewer {
  cursor: move;
  width: 100%;
  height: 600px;
  position: relative;
}

.viewing-controls {
  z-index: 100;
  border: 0;
  padding-left: 10px;
  display: flex;
  position: absolute;
  top: 16px;
  left: 0;
}

.viewing-controls .DropDownMenu ul {
  font-size: 12px;
}

.structure-edit-controls-box {
  z-index: 10;
  border: 0;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-left: 10px;
  display: flex;
  position: absolute;
  top: 16px;
  left: 0;
}

.info-canvas {
  z-index: 10;
  border: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.right-side-panel {
  width: 35%;
  min-width: 380px;
  padding-top: 18px;
}

div.h1-title {
  margin-top: 24px;
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: bold;
}

div.h2-title {
  margin-top: 24px;
  margin-bottom: 8px;
  font-size: 16px;
}

div.file-name {
  padding: 16px 0 0;
  font-size: 1.8em;
  font-weight: bolder;
}

.coordinates-field {
  justify-content: space-between;
  display: flex;
}

.lattice-scale-checkbox {
  padding: 5px 0 0 24px;
  font-size: .8em;
}

.lattice-vector-wrapper {
  padding-left: 3px;
  display: inline-block;
}

input.coor-value {
  text-align: center;
  background-color: #fff;
  border: 1px solid #999;
  border-radius: 3px;
  width: 72px;
  margin: 0 2px;
  padding-top: 2px;
  padding-bottom: 1px;
  font-weight: normal;
  display: inline-block;
}

input.coor-value:disabled {
  color: gray;
  background-color: #f4f4f4;
}

.div-table {
  border-spacing: 3px;
  width: auto;
  display: table;
}

.div-table-row {
  clear: both;
  width: auto;
  margin-bottom: 6px;
  display: flex;
}

.div-table-col {
  float: left;
  width: auto;
  display: table-column;
}

.header {
  text-align: center;
  font-size: 13px;
  font-style: italic;
  font-weight: lighter;
}

.vector {
  width: 255px;
}

.supercell-matrix-checkbox {
  margin-bottom: 10px;
  padding: 0 0 0 2px;
  font-size: .8em;
}

.supercell-row {
  margin-top: 10px;
}

.create-button-div {
  padding: 0 0 0 2px;
}

.supercell {
  padding: 6px 24px;
  font-size: .9em;
}

#Supercell input.sc-value {
  margin: 0 5px;
}

#Supercell {
  margin-top: 18px;
}

input.sc-value {
  text-align: center;
  background-color: #fff;
  border: 1px solid #999;
  border-radius: 3px;
  width: 30px;
  padding-top: 2px;
  padding-bottom: 1px;
  font-weight: normal;
  display: inline-block;
}

input.sc-value:disabled {
  color: gray;
  background-color: #f4f4f4;
}

#Surface input.miller {
  margin: 0 5px;
}

div.surface-input-div, div.kpoints-div {
  padding: 6px;
  font-size: .8em;
}

input.int-input, input.short-float-input, input.miller, input.number-of-layers, input.vacuum-layer {
  text-align: center;
  background-color: #fff;
  border: 1px solid #999;
  border-radius: 3px;
  width: 30px;
  padding-top: 2px;
  padding-bottom: 1px;
  font-weight: normal;
  display: inline-block;
}

.AtomRow {
  justify-content: space-around;
  align-items: center;
  padding: 3px 0 6px;
  font-size: 13px;
  display: flex;
}

.atom-order {
  width: 20px;
  font-size: .9em;
  display: inline-block;
}

.species {
  width: 36px;
  display: inline-block;
}

input.species-value {
  text-align: center;
  background-color: #fff;
  border: 1px solid #999;
  border-radius: 3px;
  width: 20px;
  padding-top: 2px;
  padding-bottom: 1px;
  font-weight: normal;
  display: inline-block;
}

input.species-value:disabled {
  color: gray;
  background-color: #f4f4f4;
}

input.init-moment, input.charge {
  text-align: center;
  background-color: #fff;
  border: 1px solid #999;
  border-radius: 3px;
  width: 20px;
  margin: 0 5px;
  padding-top: 2px;
  padding-bottom: 1px;
  font-weight: normal;
  display: inline-block;
}

#NewRowButton button {
  margin-bottom: 8px;
  margin-left: 30px;
  display: inline-block;
}

div#coordinates-switch {
  justify-content: center;
  align-items: center;
  width: 66%;
  padding-left: 25px;
  font-size: .8em;
}

span.species-circle {
  cursor: pointer;
}

.AtomRowTooltip {
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  width: 150px;
  position: absolute;
  top: -42px;
  left: 128%;
  box-shadow: 0 2px 5px #00000029, 0 2px 10px #0000001f;
}

.AtomRowTooltip:after {
  content: " ";
  border: 7px solid #0000;
  border-right-color: #aaa;
  margin-top: -5px;
  position: absolute;
  top: 50%;
  right: 100%;
}

.AtomRowTooltip td {
  padding: 2px 1px;
}

span.species-value {
  width: 24px;
  padding: 0 10px;
  font-size: 1.4em;
  font-weight: bold;
  display: inline-block;
}

div#atom-selector {
  text-align: center;
  padding-bottom: 10px;
  font-size: 13px;
}

.info-box {
  color: #555;
  background-color: #fafafa;
  border: 1px solid #dadada;
  border-radius: 3px;
  width: 80%;
  margin: 10px 4px;
  padding: 10px;
  font-size: .8em;
  font-style: italic;
  line-height: 1.4;
}

.info {
  text-align: center;
  width: 80%;
  margin: 0 4px;
  padding: 10px;
  line-height: 1.4;
}

.mode-wrapper {
  min-height: 90px;
}

.download-link {
  color: gray;
  font-size: 1em;
}

.download-link:link {
  color: #000;
  font-weight: bold;
}

.structure-status {
  padding: 4px;
  font-size: .8em;
  font-style: italic;
}

.top-right-btn {
  justify-content: space-between;
  width: 47%;
}

.ScreenshotTaker img, .UndoRedoComponent img {
  cursor: pointer;
}

.UndoRedoComponent {
  margin-top: 12px;
  font-weight: bolder;
}

.shortcuts-box {
  color: #bbb;
  padding-left: 20px;
  font-size: 12px;
}

.shortcuts-box table {
  border-spacing: 0;
  padding-right: 12px;
  display: inline-block;
}

.shortcuts-box td.shortcuts-title {
  padding-top: 3px;
}

.LatticeOperations {
  padding-top: 6px;
}

.StructureInfo {
  font-size: .8em;
}

.StructureInfo-ph td {
  padding-right: 20px;
}

.remove {
  cursor: pointer;
  margin: 0 0 0 12px;
  transform: translate(0%, -50%);
}

.add {
  cursor: pointer;
}

.StructureSelector-ph {
  padding: 12px 0 0 12px;
}

#OutputAnalyzerMod {
  flex-direction: column;
  justify-content: space-evenly;
  max-width: 1200px;
  margin: auto;
  display: flex;
}

.input-files-button {
  padding: 20px 40px;
}

.geometry-viz-ph {
  cursor: move;
  width: 600px;
  height: 360px;
  position: relative;
}

.geometry-viz-controls {
  justify-content: center;
  font-size: 90%;
  display: flex;
}

.geometry-viz-controls label {
  cursor: pointer;
  color: #fff;
  -webkit-user-select: none;
  user-select: none;
  background-color: gray;
  border-radius: .25em;
  margin: 3px;
  padding: 3px 6px;
  font-family: Roboto, "sans-serif";
  font-size: 90%;
  transition: all .3s;
  display: inline-block;
  box-shadow: 0 1px 3px #0003, 0 2px 2px #00000024, 0 3px 1px -2px #0000001f;
}

.atom-viz-label:active {
  transform: translateY(2px);
  box-shadow: 0 1px 3px #0003, inset 0 -1px #00000038;
}

.geometry-viz-controls input:checked + .atom-viz-label {
  color: #efefef;
  background: #6495ed;
}

.geometry-viz-controls input:checked + .atom-viz-label:hover {
  color: #e2e2e2;
  background: #4d85ea;
}

.hidden {
  display: none;
}

.bz-viewer-ph {
  cursor: move;
  width: 720px;
  height: 600px;
  margin-left: 80px;
  position: relative;
}

.band-path-table {
  margin-top: 80px;
  margin-left: 80px;
}

.system-info-section {
  justify-content: space-between;
  display: flex;
}

.f-geometry-download-link {
  font-size: smaller;
  font-weight: normal;
  text-decoration: underline;
}

.output-analyzer-importer-ph {
  border-bottom: 1px solid #ddd;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  display: flex;
}

.output-analyzer-importer-ph label {
  padding-left: 10px;
  font-size: .85em;
}

.convergence-graph-ph {
  font-size: 14px;
}

.AnimationControl {
  z-index: 10;
  cursor: default;
  border: 0;
  position: absolute;
  top: 4px;
  right: 20px;
}

.AnimationControl span {
  text-align: right;
  vertical-align: top;
  width: 42px;
  padding-right: 10px;
  line-height: 140%;
  display: inline-block;
}

.graph-box {
  justify-content: center;
  display: flex;
}

.no-output-box {
  text-align: center;
  padding: 50px 0 20px;
  font-size: 22px;
  font-style: italic;
}

.bs-error-msg {
  color: #780000;
  text-align: center;
  padding: 14px;
}

#band-structure-graph canvas, #dos-graph canvas {
  background-color: #fff;
}

.graph-controls-box {
  font-size: .8em;
}

.OutputAnalyzer-graph-controls-form {
  padding: 0 20px;
}

.OutputAnalyzer-graph-controls-form > div {
  justify-content: flex-start;
  margin: 10px 0;
  display: flex;
}

.OutputAnalyzer-graph-controls-form > div.section-title {
  text-transform: uppercase;
  padding-top: 10px;
}

.OutputAnalyzer-graph-controls-form .explicit-inclusion-box {
  box-sizing: border-box;
  width: 8%;
  display: inline-block;
}

.OutputAnalyzer-graph-controls-form div > label {
  width: 55%;
  font-weight: bold;
  display: inline-block;
}

.OutputAnalyzer-graph-controls-form .value-box {
  box-sizing: border-box;
  width: 44%;
}

.OutputAnalyzer-graph-controls-form input {
  padding: 1px 3px;
  font-size: .9em;
}

.OutputAnalyzer-graph-controls-form .ScreenshotTaker {
  margin: 0;
}

#ControlGeneratorMod {
  flex-direction: column;
  justify-content: space-evenly;
  max-width: 1200px;
  margin: auto;
  display: flex;
}

.control-gen-form {
  padding: 18px 40px;
}

.control-gen-form > div {
  justify-content: space-evenly;
  margin: 20px 0;
  display: flex;
}

.control-gen-form .explicit-inclusion-box, .control-gen-form .radio-chooser {
  box-sizing: border-box;
  width: 8%;
  display: inline-block;
}

.control-gen-form div > label {
  width: 52%;
  font-weight: bold;
  display: inline-block;
  position: relative;
}

.control-gen-form .value-box {
  box-sizing: border-box;
  width: 47%;
}

.named-subfield {
  font-size: 12px;
}

.named-subfield input {
  margin-bottom: 4px;
}

#ControlGeneratorMod input, #ControlGeneratorMod select {
  box-sizing: border-box;
  border: 1px solid #c2c2c2;
  border-radius: 3px;
  outline: none;
  width: 100%;
  padding: 5px 7px;
  box-shadow: 1px 1px 4px #ebebeb;
}

#ControlGeneratorMod .content {
  flex-direction: column;
  align-items: center;
  display: flex;
}

#ControlGeneratorMod .markedField {
  border: 2px solid red;
}

button.add-data {
  background-color: #147eff;
}

button.add-data:hover {
  background-color: #479aff;
}

button.add-data:active {
  background-color: #0065e0;
}

button.delete-data {
  background-color: #d11800;
}

button.delete-data:hover {
  background-color: #ff2205;
}

button.delete-data:active {
  background-color: #9e1200;
}

button svg {
  fill: #fff;
  vertical-align: middle;
  margin: 0;
  padding: 0;
}

.DownloadInputFilesPage {
  justify-content: space-evenly;
  max-width: 1200px;
  margin: 20px auto;
  display: flex;
}

.download-input-files-button {
  margin-bottom: 30px;
}

.download-input-files-link {
  cursor: pointer;
  color: #000;
  font-weight: bold;
  text-decoration: underline;
}

.download-input-files-link img {
  vertical-align: middle;
  padding: 0 10px;
}

.extensionChooser input {
  margin: 8px 2px;
}

.extensionChooser button {
  padding: 5px 10px;
}

.extensionChooser .button-ph {
  text-align: center;
}

.extensionChooser .radios-ph {
  padding: 10px 20px;
}

.Accordion {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 5px;
}

.Accordion .sectionContent {
  border-left: 1px solid #bbb;
  border-right: 1px solid #bbb;
  border-radius: 3px;
  padding: 8px;
}

.Accordion .sectionHeader {
  box-sizing: border-box;
  cursor: pointer;
  background-color: #eee;
  border: 1px solid #bbb;
  border-radius: 3px;
  justify-content: space-between;
  align-items: center;
  padding: 6px 12px;
  display: flex;
}

.control-gen-accordion {
  border-bottom: 1px solid #bbb;
  border-radius: 3px;
  width: 540px;
}

.structure-builder-side {
  border-bottom: 1px solid #bbb;
  border-radius: 3px;
}

.units-box {
  width: 80px;
  margin-top: 9px;
  margin-left: 17px;
  font-size: 12px;
}

.named-subfield-units {
  height: 32px;
  padding-top: 15px;
}

line.main-axis {
  stroke-width: 1px;
  stroke: #000;
}

line {
  stroke: #000;
}

.box__dragndrop, .box__uploading, .box__success, .box__error {
  display: none;
}

.box.has-advanced-upload {
  outline-offset: -10px;
  background-color: #fff;
  outline: 2px dashed #000;
  padding: 50px;
}

.box.has-advanced-upload .box__dragndrop {
  display: inline;
}

.box.is-dragover {
  background-color: gray;
}
/*# sourceMappingURL=index.ed846c4c.css.map */
