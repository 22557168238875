@import url(https://cdn.jsdelivr.net/npm/sequential-workflow-designer@0.13.2/css/designer-light.css);
@import url(https://cdn.jsdelivr.net/npm/sequential-workflow-designer@0.13.2/css/designer.css);

@font-face {
  font-family: 'Roboto';
  src: url('fonts/Roboto-Regular-webfont.woff') format('woff');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Roboto';
  src: url('fonts/Roboto-Italic-webfont.woff') format('woff');
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'Roboto';
  src: url('fonts/Roboto-Bold-webfont.woff') format('woff');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'Roboto';
  src: url('fonts/Roboto-Light-webfont.woff') format('woff');
  font-style: normal;
  font-weight: 300;
}

body{
	font-family: "Roboto", sans-serif;
	margin: 0;
	color: #444;
}

button:focus {
    outline: none;
    display: inline-block;
}

input[type="text"]{
	/*padding: 1px 3px;*/
	font-weight: normal;
	border: 1px #999 solid;
	background-color: white;
	border-radius: 3px;
	display: inline-block;
}

input[type="file"]::-webkit-file-upload-button {
  visibility: hidden;
}

input[type=range] {
  margin: 0;
}

input[type=range]::-moz-range-track {
  background-color: #CCCCCC;
}

input[type=color] {
	height: 18px;
  /*border: 0;*/
  padding: 0;
}

button, input[type="file"]{

	border: 0;
	padding: 3px 6px;
	margin: 3px;
cursor: pointer;
border-radius: 4px;
	color: #fff;
	background-color: grey; /*#2196f3;*/
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),0 2px 2px 0 rgba(0, 0, 0, 0.14),0 3px 1px -2px rgba(0, 0, 0, 0.12);
}

input[type="file"]{ /*width: 236px*/}

.img-button {padding: 0 5px; cursor: pointer; }

a:link, a:visited {
  color: inherit;
  /*text-decoration: none;*/
}

#usermanual-button {
    position: absolute;
    top: 350px;
    z-index: 500;
    color: white;
    background-color: cornflowerblue; /*crimson;  firebrick*/
    padding: 0 5px;
    font-size: 1.2em;
    border-radius: 6px 6px 0 0;
    transform: translateX(-38%) rotate(90deg);
    cursor: pointer;
}

#usermanual-button a{ text-decoration: none; }

#feedback-button {
	position: absolute;
	top: 500px;
    z-index: 500;
    color: white;
    background-color: firebrick; /*crimson;  firebrick*/
    padding: 0 5px;
    font-size: 1.2em;
    border-radius: 6px 6px 0 0;
    transform: translateX(-38%) rotate(90deg);
    cursor: pointer;
}

#feedback-button a{ text-decoration: none; }

.ModalPopup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 990;
}

.ModalPopup__overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 995;
  background: rgba(0,0,0,0.85);
}

.ModalPopup__content {
  z-index: 999;
  position: absolute;

  max-width: 60%;
  max-height: 90%;
  overflow: auto;/*scroll;*/
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  box-sizing: border-box;
  padding: 20px;
  box-shadow: 0 1px 5px rgba(0,0,0,0.7);
  border-radius: 4px;
  /*width: 520px;  This just a default width */
  /* white-space: nowrap;
  white-space: pre; */
}

/*
.modal .modal_content > h2 {
  font-size: 28px;
  font-weight: 200;
  margin: 20px 0 40px;
  text-align: center;
}

.modal .modal_content .buttons_wrapper {
  padding: 20px;
}
*/
.ModalPopup__close-button {
	z-index: 999;
  position: absolute;
  top: 2%;
  left: 48%;
  display: block;
  color: white;
  cursor: pointer;
  font-size: 12px;
}

.UserMsgBox {
  z-index: 999;
  position: absolute;
  display: block;

  max-width: 40%;
  /* max-height: 90%; */
  /*overflow: auto;*/
  top: 10px;
  left: 50%;
  transform: translate(-50%, 0);
}

.alert {
  position: relative;
  top: 10px;
  left: 0;
  width: auto;
  height: auto;
  padding: 10px;
  margin: 10px;
  line-height: 1.8;
  border-radius: 5px;
  font-family: sans-serif;
  font-weight: 400;
}

.alertText {
  display: table;
  margin: 0 auto;
  text-align: center;
  font-size: 16px;
}

.success {
  background-color: #EFE;
  border: 1px solid #DED;
  color: #9A9;
}

.notice {
  background-color: #EFF;
  border: 1px solid #DEE;
  color: #9AA;
}

.warning {
  background-color: #FDF7DF;
  border: 1px solid #FEEC6F;
  color: #C9971C;
}

.error {
  background-color: #FEE;
  border: 1px solid #EDD;
  color: #A66;
}

.UserMsgBox.error{
	color: #780000;
    background-color: #FFD2D2;
    box-shadow: 0 1px 1px #780000;
}

.DropDownMenu div:hover{
	/*
		background: #4DD0E1;
	  color: #fff;*/
	   position: relative;
	   cursor: default;
}

.DropDownMenu div:hover ul {
	  display: block;
	  opacity: 1;
	  visibility: visible;
	}

.DropDownMenu ul{
	padding: 0;
	  position: absolute;
	  top: 10px;
	  left: 0;
	  width: 150px;
	  display: none;
	  opacity: 0;
	  visibility: hidden;
	  /* transition: opacity 0.2s; */
	  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
}

.DropDownMenu ul li {
	  /* background: #00ACC1;color: #fff; */
	  display: block;
	  background:  #fff;
	  padding: 5px;
	}
/* .DropDownMenu ul li:hover { background: #4DD0E1; } */

.Switch{
}

.Switch .label{
	font-size: 14px
}

.switch {
	display: inline-block;
	box-sizing:content-box;
	padding: 0; margin: 0;
	width: 36px; height: 18px;
	border-radius: 9px;
	border: solid 2px #eceeef;
	font-size: 11px;
	text-align: center;
	line-height:20px;
	color: #aaaaaa;
	cursor: pointer;
	transition: All 0.3s ease;
}

.switch-on{
	box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15), inset 0 0 3px rgba(0, 0, 0, 0.2);
	border-color: grey;/*#9370DB;*/
	color: grey;/*#9370DB; */
	text-shadow: 0 1px rgba(0, 0, 0, 0.2);
	font-weight: bold
}

.addon-box {
    display: inline-block;
    box-sizing:content-box;
    padding: 6px 12px 6px 12px; margin-right: 6px;
    border-radius: 9px;
    border: solid 2px #eceeef;
    /*text-align: center;
    line-height:20px;
    cursor: pointer;*/
    transition: All 0.3s ease;
}

.addon {
    padding-bottom: 4px;
    cursor: pointer;
    font-size: 16px;
}

.structureSelector {
    color: #444444;
    cursor: pointer;
}

.structure-selected {
    color: black;
    border: solid 2px black;
}

.Structure-Info {
    padding-top: 18px;
}

.code-selection-box, .gims-selection-box{
	font-size: 14px;
	padding-right: 20px;
	border-right: 1px solid #CCC
}

.gims-selection-box{
	padding-top: 6px;
}

.code-selection-box img{ cursor: pointer; vertical-align: middle;}

.code-selected{
	border-radius: 5px;
	box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), inset 0 0 3px rgba(0, 0, 0, 0.2);
	border-color: grey;/*#9370DB;*/
	background:  #eee;
}

.settings-button{
	font-size: 9px;padding-left: 12px;
	text-align: center;
	cursor: pointer;
}

.tab{
margin-top: 20px;
}

.tab-item{

	padding: 5px;
	border-top: 1px solid black;
	border-left: 1px solid black;
	border-right: 1px solid black;
	cursor: pointer;
}
.tab-selected{
	font-weight: bold;
}

#app-placeholder{
}

.page-section{
	margin: 30px 0;
}

/*
table.set-of-fields tr, table.set-of-fields td{
	border: 1px solid black;
	}
*/
table.set-of-fields{
	padding: 6px;
	border-spacing: 8px;
}

.set-of-fields tr td:nth-child(1){
	font-weight: bold;
	font-size: 96%;
}

.set-of-fields tr td:nth-child(2){
	text-align: right;
}


.page-section-title{
	padding-left: 10px;
	font-size: 20px;
	font-weight: bold;
	border-bottom: 1px #DDD solid;
}

.section-content{
	display: flex;
	justify-content: space-between;
	padding-top: 16px
}

.Pagination-selector{
	text-align: center;
}

.Form{
}

.Form .user-msg{
	color: red;
	text-align: center;
	font-size: smaller;
	padding: 16px 0;
}

.Form .Form-button-box{
	text-align: center;
	margin-top: 30px;
	margin-bottom: 0;
}

.Form .Form-button-box button{
	padding: 8px 16px;
}

.Tooltip{
	position: absolute; /* top: 12px; left: 30px; */
	z-index: 100;
	padding: 10px 20px;
	box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
	/*opacity: 0.8;*/
	font-weight: normal;
	font-size: 13px;
	width: 300px;
	background-color: #ffffc8;
	border-radius: 4px;
}

.Tooltip::after{
	content: " ";
  position: absolute;
  top: 50%; /* At the bottom of the tooltip */
  right: 100%;
  margin-top: -7px;
  border-width: 7px;
  border-style: solid;
  border-color: transparent #AAA transparent transparent;
}

/***** Application header *****/

#app-header{
	max-width: 1200px;
	margin: auto;
	display: flex;
	justify-content: space-between;
	padding-top: 10px;
	padding-bottom: 16px;
}

#app-title{
	font-size: 1.15em;
	text-decoration: none;
	cursor: pointer;
}

#app-title span{
	font-weight: 300
}

.desktop-app-download{
	cursor: pointer;
	font-weight: 300;
	font-size: 0.9em
}


.settings-form{
	padding: 16px 16px 0;
}

.settings-form > div{
	margin: 20px 0;
	display: flex;
	justify-content: space-evenly;
}

.settings-form div > label{
	display: inline-block;
	width: 52%;
	font-weight: bold;
	position: relative;
	/*padding-top: 8px;*/
}

.settings-form .value-box{
	box-sizing: border-box;
	width: 40%;
}

.settings-form input, .settings-form select{
	box-sizing: border-box;
	width: 100%;
	border: 1px solid #C2C2C2;
	box-shadow: 1px 1px 4px #EBEBEB;
	border-radius: 3px;
	padding: 5px 7px;
	outline: none;

}

/********** Dashboard module ***/

#app-dashboard{
	margin-top: 50px;
	/* display: flex; */
	justify-content: space-evenly;
}

.dashboard-h1{
  margin: 0 80px 0 80px;
  font-size: 24px;
  font-weight: lighter;
  color: #AAA;
  border-bottom: 1px solid #CCC;
}

.dashboard-row{
  margin: 0 40px 40px 40px;
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
}
/* #app-dashboard > span{
	display: flex;
	flex-direction: column;
} */

.dashboard-button{
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: white;
	width: 380px;
	margin: 40px 0 40px 60px;
	font-size: 24px;
	color: #777;
	border-radius: 13px;
	cursor: pointer;
	border: 1px solid #CCC;
}

.dashboard-button img.main{
	height: 80px;
	border-radius: 13px 0 0 13px;
}

.dashboard-button img.help-button{
	cursor: help;
}

.dashboard-button img.help-button:hover ~ .dashboard-button-explanation {
	display: block;
}

.surface-input-div img.help-button{
	padding-left: 15px;
}

.surface-input-div img.help-button:hover ~ .button-explanation {
	display: block;
}

.dashboard-button-explanation{
  display: none;
 position: absolute;
 top: 65px;
 left: 0;
 padding: 25px 16px 8px;
 font-weight: lighter;
 font-size: 13px;
 z-index: -10;
 /*padding-top: 10px;*/
 border: 1px solid #CCC;
 border-radius: 13px;
}

.button-explanation {
    display: none;
	padding-top: 2px;
	padding-left: 6px;
	font-weight: lighter;
	font-size: 12px;
	color: #777;
}

/*************+ Assistant Header ********/
.AssistantHeader{
	margin: 12px auto;
	display: flex;
	max-width: 1200px;
	padding: 12px 20px 12px;
	background-color: #EEE;
	border: 1px #BBB solid;
	border-radius: 3px;
	box-sizing: border-box;
}

.prev-button-box{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 27%;
  text-align: left;
}

.workflow-centered-box{
  width: 46%;
}

.workflow-title-box{
	text-align: center;
	font-size: 1.5em;
	padding-bottom: 10px
}

.step-label-box{
	text-align: center;
	vertical-align: -20px;
	font-weight: bold;
	font-size: 1.1em;
}

.next-button-box{
	display: flex;
	justify-content: flex-end;
	align-items: center;
  width: 27%;
  text-align: right;
}

.AssistantHeader button{
	vertical-align: -20px;
	font-size: 1.2em;
	padding-top: 4px;
}

.AssistantHeader img{
	vertical-align: middle;
}

/********* WorkflowDesigner module **************/

.workflow-designer{
	margin: 12px auto;
	display: flex;
	max-width: 1200px;
	border: 1px #BBB solid;
	border-radius: 3px;
	box-sizing: border-box;
}

.sqd-toolbox {
	width:150px
}

.sqd-toolbox-filter {
	width:130px
}

.sqd-toolbox-item {
	width:130px
}

.swd-editor {
	margin: 15px;
	padding: 6px;
 	line-height: 1.4;
}

.swd-editor button {
	font-size: 1.1em;
}

.swd-editor-title {
	text-align: center;
	padding: 2mm 0;
	font-weight: bold;
}

.swd-editor-div {
	display: flex;
	justify-content: center;
}

.swd-editor-div select {
	width: 80%;
}
/********* StructureBuilder module **************/

.StructureBuilderMod{
	display: flex;
	justify-content: center;
	max-width: 1200px;
	margin: auto;

}
.structure-viewer-info{
    width: 74%; position: relative;
}

.structure-info{
width: 100%; position: relative; cursor: move; padding-left: 20px;
}
/*.GeometryImporter input[type="file"]{ box-sizing: border-box; width: 100%}*/
.GeometryImporter form{
    text-align: center;
    width: 480px;
}

.StructureViewer{  width: 100%;  height: 600px; position: relative; cursor: move}
/*  #StructureViewer canvas#threejs-canvas{border: 1px #d7d7d7 solid; border-radius: 4px;}  */
.BZViewer{  width: 100%;  height: 600px; position: relative; cursor: move}
.viewing-controls {
    position: absolute;
    /*width: 100%;*/
    left: 0;
    top: 16px;
    padding-left: 10px;
    z-index: 100;
    border: 0;
    display: flex;
}

.viewing-controls .DropDownMenu ul{
	font-size: 12px;
}

.structure-edit-controls-box{
	position: absolute;
    width: 100%;
    left: 0;
    top: 16px;
    padding-left: 10px;
    z-index: 10;
    border: 0;
    display: flex;
    flex-direction: column;
  align-items: center;

}

/*
#legend-canvas {
    position: absolute;
    left: 0px;
    top: 140px;
    z-index: 10;
    border: 0;
}
*/
.info-canvas {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    border: 0;
}


.right-side-panel {
	padding-top: 18px;
	width: 35%;
    min-width: 380px;
}

/*
#BasisAtoms div{
	padding: 3px 6px 6px 6px;

}

*/

div.h1-title{
	/* padding: 30px 0 8px;*/
	margin-top: 24px;margin-bottom: 8px;
	font-size: 18px;
	font-weight: bold;

}

div.h2-title{
	/* padding: 30px 0 8px;*/
	margin-top: 24px;margin-bottom: 8px;
	font-size: 16px

}

div.file-name{
	padding: 16px 0 0;
	font-weight: bolder;
	font-size: 1.8em;
}

/* Structure Builder -> Lattice vectors */

.coordinates-field{
	display: flex;
	justify-content: space-between;
}

.lattice-scale-checkbox{
	padding: 5px 0 0 24px;
	font-size: 0.8em;
}

.lattice-vector-wrapper{
    display: inline-block;
    padding-left: 3px;
    }

input.coor-value{
    padding-top: 2px;
    padding-bottom: 1px;
    margin: 0 2px;
    font-weight: normal;
    border: 1px #999 solid;
    background-color: white;
    border-radius: 3px;
    width: 72px;
    display: inline-block;
    text-align: center;
}

input.coor-value:disabled{
    color: grey;
    background-color: #F4F4F4;
}

.div-table {
    display: table;
    width: auto;
    border-spacing: 3px;
}

.div-table-row {
    display: flex;
    width: auto;
    clear: both;
		margin-bottom: 6px;
}

.div-table-col {
    float: left;
    display: table-column;
    width: auto;
  }

.header {
    text-align: center;
    font-size: 13px;
    font-style: italic;
    font-weight: lighter
}

.vector{
    width: 255px;
}

.supercell-matrix-checkbox{
    padding: 0 0 0 2px;
    font-size: 0.8em;
    margin-bottom: 10px;
}

.supercell-row{
    margin-top: 10px;
}

.create-button-div{
    padding: 0 0 0 2px;
}

.supercell{
    padding: 6px 24px;
    font-size: 0.9em;
     }

#Supercell input.sc-value{
    margin: 0 5px;
}

#Supercell {
   margin-top: 18px;
}

input.sc-value{ padding-top: 2px; padding-bottom: 1px;
    /*margin: 0 5px;*/
    font-weight: normal;
    border: 1px #999 solid;
    background-color: white;
    border-radius: 3px;
    width: 30px;
    display: inline-block;
    text-align: center;
}

input.sc-value:disabled{
    color: grey;
    background-color: #F4F4F4;
}

#Surface input.miller{
    margin: 0 5px;
}

div.surface-input-div,
div.kpoints-div{
	padding: 6px;
    font-size: 0.8em;
}

input.int-input,
input.short-float-input,
input.miller,
input.number-of-layers,
input.vacuum-layer{
	padding-top: 2px; padding-bottom: 1px;
    /*margin: 0 5px;*/
    font-weight: normal;
    border: 1px #999 solid;
    background-color: white;
    border-radius: 3px;
    width: 30px;
    display: inline-block;
    text-align: center;
}


.AtomRow{
	display: flex;
	justify-content: space-around; /*space-between;*/
	align-items: center;
	padding: 3px 0 6px 0;
	 font-size: 13px;
}

.atom-order{
	font-size: 0.9em;
	width: 20px;
	display: inline-block;
}

.species{
	width: 36px;
	display: inline-block;
}

input.species-value{
	padding-top: 2px;
	padding-bottom: 1px;
	font-weight: normal;
	border: 1px #999 solid;
	background-color: white;
	border-radius: 3px;
	width: 20px;
	display: inline-block;
	text-align: center;
}

input.species-value:disabled{
	color: grey;
	background-color: #F4F4F4;
}

input.init-moment,
input.charge {
    padding-top: 2px; padding-bottom: 1px; margin: 0 5px;
    font-weight: normal;
    border: 1px #999 solid;
    background-color: white;
    border-radius: 3px;
    width: 20px;
    display: inline-block;
    text-align: center;
}

#NewRowButton button{
	/*float: right;*/
	display: inline-block;
	margin-left: 30px;
	margin-bottom: 8px;
}

div#coordinates-switch{
	font-size: 0.8em;
    justify-content: center;
    align-items:center;
	padding-left: 25px;
	width: 66%
}


span.species-circle{
	cursor: pointer;
}

.AtomRowTooltip{
	position: absolute; top: -42px; left: 128%;
	box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
	background-color: white; /* white #AAA */
	/* color: white;*/
	width: 150px;
	border-radius: 3px;
	border: 1px solid #CCC;

	z-index: 1000;
}

.AtomRowTooltip::after{
	content: " ";
  position: absolute;
  top: 50%; /* At the bottom of the tooltip */
  right: 100%;
  margin-top: -5px;
  border-width: 7px;
  border-style: solid;
  border-color: transparent #AAA transparent transparent;
}

.AtomRowTooltip td{
	padding: 2px 1px;
	}



span.species-value{
	padding: 0 10px;
	font-size: 1.4em;
	font-weight: bold;
	display: inline-block;
	width: 24px;
}

div#atom-selector{
	text-align: center;
	padding-bottom: 10px;
	font-size: 13px
}



.info-box{
	width: 80%;
	margin: 10px 4px;
	padding: 10px;
	background-color: #FAFAFA;/* #fffdf0; */
	border: 1px solid #DADADA; /*#EFDF64; #E8BF34;*/
	border-radius: 3px;
	font-size: 0.8em;
	font-style: italic;
	color: #555;/*#B98802; */
	 line-height: 1.4;

	/* <a class="left block" onclick="return false;" style="width: 634px; height: 922px; background-color: #FFE063;"></a>*/
}

.info{
	width: 80%;
	margin: 0 4px;
	padding: 10px;
 	line-height: 1.4;
	text-align: center;
	/* <a class="left block" onclick="return false;" style="width: 634px; height: 922px; background-color: #FFE063;"></a>*/
}
.mode-wrapper{ min-height: 90px }

.download-link{
	font-size: 1em;
	color: grey;
}

.download-link:link{
	font-weight: bold;
	color: black;
}

.structure-status{
	padding: 4px;
	font-size: 0.8em;
	font-style: italic;
}

.top-right-btn {
  width: 47%;
  justify-content: space-between;
}

.ScreenshotTaker{
	/*margin-top: 7px;*/
}

.ScreenshotTaker img, .UndoRedoComponent img{
	cursor: pointer;
}

.UndoRedoComponent{
	margin-top: 12px;
	font-weight: bolder;
}

.shortcuts-box{
    padding-left: 20px;
	font-size: 12px;
	color: #BBB;
}
.shortcuts-box table{
	border-spacing: 0;
    display: inline-block;
    padding-right: 12px;
}

.shortcuts-box td.shortcuts-title
{
	padding-top: 3px;
}

.LatticeOperations {
    padding-top: 6px;
}

.StructureInfo{
	font-size: 0.8em;
}

.StructureInfo-ph td{
    padding-right: 20px;
}

.remove {
  cursor: pointer;
  margin: 0 0 0 12px;
  transform: translate(0%, -50%);
}

.add {
  cursor: pointer;

}

.StructureSelector-ph {
  padding: 12px 0 0 12px;
}


/********* OutputAnalyzer module **************/

#OutputAnalyzerMod{
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	max-width: 1200px;
	margin: auto;

}


.input-files-button{
	padding: 20px 40px;
}

.geometry-viz-ph{
	position: relative;
	/*width: 40%;*/
	width: 600px;
	height: 360px;
	cursor: move;
}

.geometry-viz-controls{
	display: flex;
	justify-content: center;
	font-size: 90%;
}

.geometry-viz-controls label{
	font-size: 90%;
	font-family: "Roboto", "sans-serif";
	display: inline-block;
	padding: 3px 6px;
	margin: 3px;
	cursor: pointer;
	color: #fff;
	border-radius: 0.25em;
	background-color: grey;
	box-shadow: 0 1px 3px #0003, 0 2px 2px #00000024, 0 3px 1px -2px #0000001f;
	transition: 0.3s;
	user-select: none;
}

.atom-viz-label:active {
	transform: translateY(2px);
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2), inset 0 -1px 0 rgba(0, 0, 0, 0.22);
}
.geometry-viz-controls input:checked + .atom-viz-label {
	background: cornflowerblue;
	color: #efefef;
}
.geometry-viz-controls input:checked +  .atom-viz-label:hover {
	background: #4d85ea;
	color: #e2e2e2;
}

.hidden {
	display: none;
}

.bz-viewer-ph{
    position: relative;
    margin-left: 80px;
    /*width: 40%;*/
    width: 720px;
    height: 600px;
    cursor: move;
}

.band-path-table{
    margin-left: 80px;
    margin-top: 80px
}

.system-info-section{
	display: flex;
	justify-content: space-between;
}

.f-geometry-download-link{
	text-decoration: underline;
	font-size: smaller;
	font-weight: normal
}


.output-analyzer-importer-ph{
	display: flex;
	justify-content: center;
	align-items: center;
	border-bottom: 1px #DDD solid;
	padding: 20px 0;
	/* text-align: center;
	font-size: 1.2em*/
}

.output-analyzer-importer-ph label{
	padding-left: 10px;
	font-size: 0.85em
}

.convergence-graph-ph{
	font-size: 14px
}

.AnimationControl{
	position: absolute;
    right: 20px;
    top: 4px;
    z-index: 10;
    border: 0;
    cursor: default
}

.AnimationControl span{
	display: inline-block;
	width: 42px;
	text-align: right;
	padding-right: 10px;
	line-height: 140%;
	vertical-align: top;
}

.graph-box{
	display: flex;
	justify-content: center;
	/*align-items: center;*/
}

.no-output-box{
	font-size: 22px;
	padding: 50px 0 20px 0;
	font-style: italic;
	text-align: center
}

.bs-error-msg{
	color: #780000;
    /*background-color: #FFD2D2;*/
	text-align: center;
	padding: 14px;
}

#band-structure-graph canvas{ background-color: white; /*width: 700px; height: 500px; */ }

#dos-graph canvas{ background-color: white }

.graph-controls-box{font-size: 0.8em}

.OutputAnalyzer-graph-controls-form{
	padding: 0 20px;
}

.OutputAnalyzer-graph-controls-form > div{
	margin: 10px 0;
	display: flex;
	justify-content: flex-start; /*space-evenly;*/
}

.OutputAnalyzer-graph-controls-form > div.section-title{
	/*font-size: 1.1em;*/
	padding-top: 10px;
	/*font-style: italic;*/
	text-transform: uppercase;
	}

.OutputAnalyzer-graph-controls-form .explicit-inclusion-box{
	box-sizing: border-box;
	display:inline-block;
	width: 8%;
}

.OutputAnalyzer-graph-controls-form div > label{
	display: inline-block;
	width: 55%;
	font-weight: bold;
	/*padding-top: 8px;*/
}

.OutputAnalyzer-graph-controls-form .value-box{
	box-sizing: border-box;
	width: 44%;
}

.OutputAnalyzer-graph-controls-form input{
	padding: 1px 3px;
  font-size: 0.9em
}


.OutputAnalyzer-graph-controls-form .ScreenshotTaker{
	margin: 0;
}




/********* ControlGenerator module **************/

#ControlGeneratorMod{
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	max-width: 1200px;
	margin: auto;

}

.control-gen-form{
	padding: 18px 40px;
	}

.control-gen-form > div{
	margin: 20px 0;
	display: flex;
	justify-content: space-evenly;

}

.control-gen-form .explicit-inclusion-box{
	box-sizing: border-box;
	display:inline-block;
	width: 8%;
}

.control-gen-form .radio-chooser{
	box-sizing: border-box;
	display:inline-block;
	width: 8%;
}

.control-gen-form div > label{
	display: inline-block;
	width: 52%;
	font-weight: bold;
	position: relative;

	/*padding-top: 8px;*/
}

.control-gen-form .value-box{
	box-sizing: border-box;
	width: 47%;
}

.named-subfield{
	font-size: 12px;
}

.named-subfield input{
	margin-bottom: 4px
}

#ControlGeneratorMod input, #ControlGeneratorMod select{
	box-sizing: border-box;
	width: 100%;
	border: 1px solid #C2C2C2;
	box-shadow: 1px 1px 4px #EBEBEB;
	border-radius: 3px;
	padding: 5px 7px;
	outline: none;

}

#ControlGeneratorMod .content{
	display: flex;   flex-direction: column;  align-items: center;
}

#ControlGeneratorMod .markedField{
	border: 2px solid red;
}

button.add-data {
  background-color: #147eff;
}

button.add-data:hover {
	background-color: #479aff;
}

button.add-data:active {
  background-color: #0065e0;
}

button.delete-data {
  background-color: #d11800;
}

button.delete-data:hover {
  background-color: #ff2205;
}

button.delete-data:active {
  background-color: #9e1200;
}

button svg {
  fill: #ffffff;
  vertical-align: middle;

  padding: 0;
  margin: 0;
}

.DownloadInputFilesPage{
	margin: 20px auto;
	max-width: 1200px;
	display: flex;
	justify-content: space-evenly;
}

.download-input-files-button{
	margin-bottom: 30px;
}

.download-input-files-link{
	cursor: pointer;
	text-decoration: underline;
	font-weight: bold;
	color: black;
}

.download-input-files-link img{
	vertical-align: middle;
	padding: 0 10px
}

.extensionChooser input{
	 margin: 8px 2px;
}


.extensionChooser button{
	padding: 5px 10px;
}

.extensionChooser .button-ph{
	text-align: center
}

.extensionChooser .radios-ph{
	padding: 10px 20px
}

.Accordion{
  margin-top: 10px;
  margin-left: 5px;
  margin-bottom: 10px;
}

.Accordion .sectionContent{

	border-left: 1px #BBB solid;
	border-right: 1px #BBB solid;
	border-radius: 3px;
    padding: 8px;
}

.Accordion .sectionHeader{
	display: flex;
	justify-content: space-between;
	 align-items: center;
	padding: 6px 12px;
	background-color: #EEE;
	border: 1px #BBB solid;
	border-radius: 3px;
	box-sizing: border-box;
	cursor: pointer;
}

.control-gen-accordion{
	width: 540px;
	border-bottom: 1px #BBB solid;
	border-radius: 3px;
}

.structure-builder-side{
    border-bottom: 1px #BBB solid;
    border-radius: 3px;
}

.units-box{
    width: 80px;
    font-size: 12px;
    margin-left: 17px;
    margin-top: 9px;
}

.named-subfield-units{
    height: 32px;
    padding-top:15px;
}


line.main-axis{stroke-width: 1; stroke: black;}

line{stroke: black;}

.box__dragndrop,
.box__uploading,
.box__success,
.box__error {
  display: none;
}

.box.has-advanced-upload {
  background-color: white;
  outline: 2px dashed black;
  outline-offset: -10px;
  padding:50px;
}
.box.has-advanced-upload .box__dragndrop {
  display: inline;
}

.box.is-dragover {
  background-color: grey;
}

/******* BS/DOS

.zeroline{stroke-width: 1; stroke: #CCC;}
polyline.plotSpin1{ stroke: #f26430; stroke-width: 2; fill: none}
polyline.plotSpin2{ stroke: #009ddc; stroke-width: 2; fill: none}
line.segment{ stroke: black; stroke-width: 0.2;
stroke-dasharray: 10,16;
}
text.axis-steps{
  font-size: 12px;
}
text.axis-steps-smaller{
  font-size: 11px;
}
text.axis-steps-small{
  font-size: 10px;
}
text.axis-steps-big{
  font-size: 14px;
}

.out-of-range{
  fill: #EEE;
}

.cbm-vbm-points{
  fill: #333;
}

***/
